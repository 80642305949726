body {
  margin: 0;
  overflow-x: hidden;

}

/* Apply Poppins globally */
body {
  font-family: 'Poppins', sans-serif;
}

/* Or apply to specific elements */
.header {
  font-family: 'Poppins', sans-serif;
}

.button {
  font-family: 'Poppins', sans-serif;
}
