.pfp-generator {
  position: relative;
  z-index: 200;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2vh;
  background: radial-gradient(rgba(26, 30, 37, 0.95) 0%, rgba(26, 30, 37, 1) 60%);
  color: #FAF9DE;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: hidden;
}

.pfp-preview {
  position: relative;
  width: 400px;
  height: 400px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  margin-left: 15%;
}

.base-image {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay.background {
  z-index: 0;
}

.overlay.left-clothes {
  z-index: 1;
}

.overlay.left-hat {
  z-index: 2;
}

.overlay.left-accessory {
  z-index: 3;
}

.overlay.right-clothes {
  z-index: 4;
}

.overlay.right-hat {
  z-index: 5;
}

.overlay.right-accessory {
  z-index: 6;
}

.controls {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 40vw;
}

.control-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.control-options-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: #545454;
}

.control-options {
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  overflow-y: hidden; /* Prevent vertical scrolling */
  scroll-behavior: smooth;
  flex-wrap: nowrap;
}

.control-options img {
  width: 70px; /* clothing options image size */
  height: 70px;
  cursor: pointer;
  border: 5px solid black;
  transition: transform 0.3s, border 0.3s, background-color 0.3s;
}

.control-options img:hover {
  background-color: rgb(174, 174, 174);
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.control-options img.selected {
  border-color: rgba(242, 121, 0, 0.914);
}

.arrow {
  cursor: pointer;
  font-size: 20px;
  background: none;
  border: none;
  color: #FAF9DE;
  transition: color 0.3s;
  width: 30px; /* Same width as the clothing options */
  height: 70px; /* Same height as the clothing options */
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:hover {
  color: #ffffff;
}

button, .upload-label {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
  text-align: center;
}

button:hover, .upload-label:hover {
  background-color: rgba(242, 121, 0, 0.914);
}

input[type="file"] {
  display: none;
}

/* Hide scrollbar */
.control-options::-webkit-scrollbar {
  display: none;
}

.control-options {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.caption {
  position: absolute;
  bottom: 23%; /* Adjusted for new positioning */
  left: 50%;
  transform: translate(-50%, 50%);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 2px;
  font-size: 14px;
  z-index: 8;
  word-wrap: break-word;
  white-space: pre-wrap; /* Ensure the caption wraps */
  line-height: 1.2; /* Reduce gap between lines */
}

/* Extra Small Devices (phones) */
@media (max-width: 575.98px) {
  .caption {
    position: absolute;
    bottom: 23%; /* Adjusted for new positioning */
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 2px;
    font-size: 3.5px;
    z-index: 8;
    word-wrap: break-word;
    white-space: pre-wrap; /* Ensure the caption wraps */
    line-height: 1.2; /* Reduce gap between lines */
  }

  .controls {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #333;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 55vw;
  }

  .control-options img {
    width: 30px; /* clothing options image size */
    height: 30px;
    cursor: pointer;
    border: 5px solid black;
    transition: transform 0.3s, border 0.3s, background-color 0.3s;
  }

  .pfp-preview {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-left: 5%;
  }
  
  .base-image {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .arrow {
    cursor: pointer;
    font-size: 20px;
    background: none;
    border: none;
    color: #FAF9DE;
    transition: color 0.3s;
    width: 10px; /* Same width as the clothing options */
    height: 30px; /* Same height as the clothing options */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Small Devices (landscape phones) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .caption {
    position: absolute;
    bottom: 23%; /* Adjusted for new positioning */
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 2px;
    font-size: 5.7px;
    z-index: 8;
    word-wrap: break-word;
    white-space: pre-wrap; /* Ensure the caption wraps */
    line-height: 1.2; /* Reduce gap between lines */
  }

  .controls {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 55vw;
  }

  .control-options img {
    width: 30px; /* clothing options image size */
    height: 30px;
    cursor: pointer;
    border: 5px solid black;
    transition: transform 0.3s, border 0.3s, background-color 0.3s;
  }

  .pfp-preview {
    position: relative;
    width: 170px;
    height: 170px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-left: 3%;
  }
  
  .base-image {
    width: 170px;
    height: 170px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .arrow {
    cursor: pointer;
    font-size: 20px;
    background: none;
    border: none;
    color: #FAF9DE;
    transition: color 0.3s;
    width: 10px; /* Same width as the clothing options */
    height: 50px; /* Same height as the clothing options */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Medium Devices (tablets) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .caption {
    position: absolute;
    bottom: 23%; /* Adjusted for new positioning */
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 2px;
    font-size: 8.5px;
    z-index: 8;
    word-wrap: break-word;
    white-space: pre-wrap; /* Ensure the caption wraps */
    line-height: 1.2; /* Reduce gap between lines */
  }

  .controls {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 50vw;
  }

  .control-options img {
    width: 60px; /* clothing options image size */
    height: 60px;
    cursor: pointer;
    border: 5px solid black;
    transition: transform 0.3s, border 0.3s, background-color 0.3s;
  }

  .pfp-preview {
    position: relative;
    width: 250px;
    height: 250px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-left: 5%;
  }
  
  .base-image {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1.
  }

  .arrow {
    cursor: pointer;
    font-size: 20px;
    background: none;
    border: none;
    color: #FAF9DE;
    transition: color 0.3s;
    width: 10px; /* Same width as the clothing options */
    height: 50px; /* Same height as the clothing options */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .caption {
    position: absolute;
    bottom: 23%; /* Adjusted for new positioning */
    left: 50%;
    transform: translate(-50%, 50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 2px;
    font-size: 10px;
    z-index: 8;
    word-wrap: break-word;
    white-space: pre-wrap; /* Ensure the caption wraps */
    line-height: 1.2; /* Reduce gap between lines */
  }

  .controls {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 50vw;
  }

  .control-options img {
    width: 70px; /* clothing options image size */
    height: 70px;
    cursor: pointer;
    border: 5px solid black;
    transition: transform 0.3s, border 0.3s, background-color 0.3s;
  }

  .pfp-preview {
    position: relative;
    width: 300px;
    height: 300px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-left: 7%;
  }
  
  .base-image {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1.
  }

  .arrow {
    cursor: pointer;
    font-size: 20px;
    background: none;
    border: none;
    color: #FAF9DE;
    transition: color 0.3s;
    width: 10px; /* Same width as the clothing options */
    height: 50px; /* Same height as the clothing options */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.caption-input {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}
