/* Intro */
.intro-container .skip-button {
  position: fixed;
  z-index: 1001;
  bottom: 60px;
  right: 30px;
  padding: 10px;
  font-size: 10;
  background-color: #FAF9DE;
  color: rgb(26, 26, 26);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.intro-container .skip-button:hover {
  transform: scale(1.1);
}

.intro-container .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 1000; /* Ensure it covers the video */
}

.intro-container .videoTag {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-attachment: fixed;
}

.intro-container .introVideo {
  z-index: 1000; /* Ensure this is higher to be on top */
}

.intro-container .videoTag.introVideo {
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1; /* Ensure the video remains visible */
  transition: opacity 1s ease; /* Smooth transition for opacity */
  pointer-events: auto; /* Allow pointer events when visible */
  object-fit: contain; /* Fit the video to the width of the screen while maintaining aspect ratio */
}

.intro-container .videoTag.introVideo.hide {
  opacity: 0; /* Hide the video by reducing opacity */
  pointer-events: none; /* Disable pointer events when hidden */
}

.intro-container .mute-button {
  position: fixed;
  top: 40px; /* Adjust top position as needed */
  left: 5px; /* Adjust left position as needed */
  z-index: 1000; /* Ensure it stays on top of other content */
  width: 70px; /* Adjust width as needed */
  background-color: transparent; /* Make the background transparent */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer */
}

.intro-container .mute-button img {
  width: 100%; /* Ensure the icon fills the button */
  height: 100%; /* Ensure the icon fills the button */
  object-fit: contain; /* Preserve aspect ratio of the icon */
}


/* Extra Small Devices (phones) */
@media (max-width: 575.98px) {
  .intro-container .mute-button {
    position: fixed;
    top: 25px; /* Adjust top position as needed */
    left: -5px; /* Adjust left position as needed */
    z-index: 1000; /* Ensure it stays on top of other content */
    width: 70px; /* Adjust width as needed */
    background-color: transparent; /* Make the background transparent */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor to pointer */
  }
}

/* Small Devices (landscape phones) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Medium Devices (tablets) */
@media (min-width: 768px) and (max-width: 991.98px) {

}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}
