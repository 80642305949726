/* Landing */
.landing {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.landing h1 {
  color: white;
}

.landing button {
  padding: 1vw 2vw;
  font-size: 'Poppins';
  border-radius: 100px;
  border: none;
  transition: all 0.3s ease-out
}

.landing button:hover {
  transform: scale(1.1);
}