.main-page-contents {
  position: relative;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  background: radial-gradient(rgba(26, 30, 37, 0.95) 0%, rgba(26, 30, 37, 1) 60%);
  padding-top: 12px;
  padding-bottom: 16px;
}

.contents-2 {
  background: radial-gradient(rgba(36, 41, 50, 0.98) 0%, rgba(36, 41, 50, 1) 50%);
  z-index: 2010;
}

.image-container {
  margin: auto;
  overflow-y: auto;
  max-height: 70vh;
  place-items: center;
  width: 80%;
}

.image, .video-content {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.button-container {
  display: flex;
}

.button {
  margin-right: 10px;
  transition: background-color 0.1s, opacity 0.1s;
  margin-top: 0;
  width: 100px !important;
}

.button:hover {
  background-color: #fbac34; /* Lighter gray */
}

.button:disabled {
  background-color: #777; /* Even lighter gray */
  opacity: 0.7;
  cursor: not-allowed;
  border: 2px solid #999; /* Light gray border */
  color: #ccc; /* Light gray text */
}