.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10; /* Keep this low to stay in the background */
}

.videoTag {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
