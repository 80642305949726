/* Marquee */
.marquee {
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    height: 50px;
    background-color: #1A1E25;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.51);
    z-index: 2200;
    color: #FAF9DE;
  }
  
  .track {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
  }
  
  @keyframes marquee {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-50%); } /* Move only half the width to ensure seamless looping */
  }

.marquee2 {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #f0f0f0; /* Optional */
  }
  
  .marquee-content2 {
    display: block;
    width: auto;
    white-space: nowrap;
    position: absolute;
    animation: marquee2 10s linear infinite;
  }
  
  @keyframes marquee2 {
    from { transform: translateX(100%); }
    to { transform: translateX(-100%); }
  }