/* Main Page */
.main-page-container {
  z-index: 300;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-page-contents {
  position: relative;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vh;
  background: radial-gradient(rgba(26, 30, 37, 0.95) 0%, rgba(26, 30, 37, 1) 60%);
}

.main-page-contents h1, h2, h3 {
  color: #FAF9DE;
  text-align: center;
  margin: 0;
}

.main-page-contents h1 {
  font-size: 6vw;
}

.main-page-contents h2 {
  font-size: 2.5vw;
}

.main-page-contents h3 {
  font-size: 1.5vw;
}

.main-page-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  z-index: 3000; /* Ensure it's on top */
  position: relative; /* Make sure it's positioned contextually */
}

.main-page-contents button {
  width: 8vw;
  padding: 1vh 0;
  border-radius: 100px;
  background-color: #FAF9DE;
  color: rgb(26, 26, 26);
  border: none;
  cursor: pointer; /* Make it obvious it's clickable */
  transition: all 0.3s ease;
}

.main-page-contents button:hover {
  transform: scale(1.05);
}

.contents-2 {
  background: radial-gradient(rgba(36, 41, 50, 0.98) 0%, rgba(36, 41, 50, 1) 50%);
  z-index: 2010;
}

.contents-3 {
  z-index: 2050;
}

.contents-2 h2, h3 {
  width: 50%;
  opacity: 0.8;
}

.contents-2 h1 {
  width: 80%;
  line-height: 1; /* Adjust line-height to reduce vertical gap */
}

.contents-3 h2, h3 {
  width: 50%;
  opacity: 0.8;
}

.contents-3 h1 {
  width: 80%;
}

.img-section2-bottomLeft, .img-section1-bottomRight {
  position: absolute;
  width: 20vw;
  bottom: -2vh;
  animation: sway 1s ease-in-out infinite, jump 2s ease-in-out infinite;
  z-index: 2000;
}

.img-section1-bottomRight {
  right: 0;
}

.img-section2-bottomLeft {
  left: 0;
}

.logo {
  width: 100px; /* Default size for desktop */
  height: auto;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.1);
}

@keyframes sway {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px); /* Adjust the value for more or less sway */
  }
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the value for higher or lower jumps */
  }
}

/* Extra Small Devices (phones) */
@media (max-width: 575.98px) {
  .main-page-contents h1 {
    font-size: 20vw;
  }

  .main-page-contents.contents-2 h1 {
    font-size: 10vw;
  }

  .main-page-contents h2 {
    font-size: 20px;
    max-width: 90vw; /* Expand to the edge of the screen */
    white-space: normal; /* Ensure text wraps */
    word-wrap: break-word; /* Break long words */
    width: 100%; /* Ensure it uses the full width */
    padding: 0 5vw; /* Add some padding for better appearance */
  }

  .main-page-contents h3 {
    font-size: 3vw;
    white-space: normal; /* Ensure text wraps */
    word-wrap: break-word; /* Break long words */
  }

  .img-section2-bottomLeft, .img-section1-bottomRight {
    position: absolute;
    width: 25vh;
    bottom: -2vh;
    animation: sway 1s ease-in-out infinite, jump 2s ease-in-out infinite;
    z-index: 2000;
  }

  .img-section1-bottomRight {
    right: 0;
  }

  .img-section2-bottomLeft {
    left: 0;
  }

  .main-page-buttons-container {
    flex-direction: row;
    flex-wrap: wrap; /* Allow logos to wrap onto new line */
    justify-content: center; /* Center logos horizontally */
    max-width: 300px; /* Set a maximum width for the container */
    margin: 0 auto; /* Center the container */
  }

  .logo {
    width: 60px;
    height: auto;
    margin: 0 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
}

/* Small Devices (landscape phones) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-page-contents h1 {
    font-size: 10vw;
  }
  .main-page-contents h2 {
    font-size: 3vw;
  }
  .main-page-contents h3 {
    font-size: 2vw;
  }

  .img-section2-bottomLeft, .img-section1-bottomRight {
    position: absolute;
    width: 30vw;
    bottom: -2vh;
    animation: sway 1s ease-in-out infinite, jump 2s ease-in-out infinite;
    z-index: 2000;
  }

  .img-section1-bottomRight {
    right: 0;
  }

  .img-section2-bottomLeft {
    left: 0;
  }

  .logo {
    width: 70px;
    height: auto;
    margin: 0 4px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
}

/* Medium Devices (tablets) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-page-contents h1 {
    font-size: 8vw;
  }
  .main-page-contents h2 {
    font-size: 3vw;
  }
  .main-page-contents h3 {
    font-size: 2vw;
  }

  .img-section2-bottomLeft, .img-section1-bottomRight {
    position: absolute;
    width: 25vw;
    bottom: -2vh;
    animation: sway 1s ease-in-out infinite, jump 2s ease-in-out infinite;
    z-index: 2000;
  }

  .img-section1-bottomRight {
    right: 0;
  }

  .img-section2-bottomLeft {
    left: 0;
  }

  .logo {
    width: 80px;
    height: auto;
    margin: 0 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
}

/* Large Devices (desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-page-contents h1 {
    font-size: 8vw;
  }
  .main-page-contents h2 {
    font-size: 3vw;
  }
  .main-page-contents h3 {
    font-size: 2vw;
  }
}

@media (max-width: 768px) {
  .game-text {
    margin-top: 16px !important;
    margin-left: 20px;
    padding-right: 150px;
    font-size: 24px !important;
  }
}